/* eslint-disable no-fallthrough */
import { typeCheckConfig, getjQuery } from './mdb/util/index';
import Data from './mdb/dom/data';
import Manipulator from './mdb/dom/manipulator';
import EventHandler from './mdb/dom/event-handler';
import SelectorEngine from './mdb/dom/selector-engine';

import create from './templates';
/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'WYSIWYG';
const DATA_KEY = 'mdb.WYSIWYG';
const SELECTOR_EXPAND = '[data-wysiwyg="wysiwyg"]';
const COMMAND_SELECTOR = '[data-cmd]';

const COLORS = [
  '#1266F1', // Primary
  '#B23CFD', // Secondary
  '#00B74A', // Success
  '#F93154', // Danger
  '#FFA900', // Warning
  '#39C0ED', // Info
  '#FBFBFB', // Light
  '#262626', // Dark
];

const TRANSLATIONS = {
  paragraph: 'Paragraph',
  textStyle: 'Text style',
  heading: 'Heading',
  preformatted: 'Preformatted',
  bold: 'Bold',
  italic: 'Italic',
  strikethrough: 'Strikethrough',
  underline: 'Underline',
  textcolor: 'Color',
  textBackgroundColor: 'Background Color',
  alignLeft: 'Align Left',
  alignCenter: 'Align Center',
  alignRight: 'Align Right',
  alignJustify: 'Align Justify',
  insertLink: 'Insert Link',
  insertPicture: 'Insert Picture',
  unorderedList: 'Unordered List',
  orderedList: 'Numbered List',
  increaseIndent: 'Increase Indent',
  decreaseIndent: 'Decrease Indent',
  insertHorizontalRule: 'Insert Horizontal Line',
  showHTML: 'Show HTML code',
  undo: 'Undo',
  redo: 'Redo',
  addLinkHead: 'Add Link',
  addImageHead: 'Add Image',
  linkUrlLabel: 'Enter a URL:',
  linkDescription: 'Enter a description',
  imageUrlLabel: 'Enter a URL:',
  okButton: 'OK',
  cancelButton: 'cancel',
  moreOptions: 'Show More Options',
};

const DEFAULT_OPTIONS = {
  wysiwygColors: COLORS,
  wysiwygTranslations: TRANSLATIONS,
  wysiwygStylesSection: true,
  wysiwygFormattingSection: true,
  wysiwygJustifySection: true,
  wysiwygListsSection: true,
  wysiwygLinksSection: true,
  wysiwygShowCodeSection: true,
  wysiwygUndoRedoSection: true,
  wysiwygFixed: false,
  wysiwygFixedOffsetTop: 0,
};

const OPTIONS_TYPE = {
  wysiwygColors: 'array',
  wysiwygTranslations: 'object',
  wysiwygStylesSection: 'boolean',
  wysiwygFormattingSection: 'boolean',
  wysiwygJustifySection: 'boolean',
  wysiwygListsSection: 'boolean',
  wysiwygLinksSection: 'boolean',
  wysiwygShowCodeSection: 'boolean',
  wysiwygUndoRedoSection: 'boolean',
  wysiwygFixed: 'boolean',
  wysiwygFixedOffsetTop: 'number',
};

const CLASS_PREFIX = 'wysiwyg';
const CONTENT_CLASS = `${CLASS_PREFIX}-content`;
const TOOLBAR_CLASS = `${CLASS_PREFIX}-toolbar`;
const TOOLBAR_TOGGLER_CLASS = `${CLASS_PREFIX}-toolbar-toggler`;
const TOOLBAR_GROUP_CLASS = `${CLASS_PREFIX}-toolbar-group`;
const HIDE_CLASS = `${CLASS_PREFIX}-hide`;
const SHOW_HTML_CLASS = `${CLASS_PREFIX}-show-html`;

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class WYSIWYG {
  constructor(element, options = {}) {
    this._element = element;
    this._content = '';
    this._toolbar = '';
    this._toolbarToggler = '';
    this._options = this._getConfig(options);
    this._isCodeShown = false;
    this._toolsWidth = [];
    this._selection = {};
    this._UUID = this._randomUUID();

    if (this._element) {
      this._init();
      Data.setData(element, DATA_KEY, this);
    }
  }

  // Getters

  // Public
  dispose() {
    Data.removeData(this._element, DATA_KEY);

    EventHandler.off(window, 'resize');
    EventHandler.off(this._content, 'input');

    SelectorEngine.find(COMMAND_SELECTOR, this._element).forEach((el) => {
      EventHandler.off(el, 'click');
    });

    if (this._toolbarToggler) {
      EventHandler.off(
        SelectorEngine.findOne(`.${TOOLBAR_TOGGLER_CLASS} .dropdown`),
        'hide.bs.dropdown'
      );
      EventHandler.off(SelectorEngine.findOne(`.${TOOLBAR_TOGGLER_CLASS} .dropdown`), 'mousedown');
    }

    this._element = null;
    this._options = null;
    this._isCodeShown = null;
    this._toolbarToggler = null;
    this._toolsWidth = null;
    this._selection = null;
    this._UUID = null;
  }

  getCode() {
    return this._content.innerHTML;
  }

  // Private
  _init() {
    Manipulator.setDataAttribute(this._element, 'uuid', this._UUID);

    const initialContent = this._element.innerHTML;
    const wrapper = document.createElement('div');

    wrapper.innerHTML = create.contentDiv();

    const content = wrapper.firstChild;

    this._element.innerHTML = '';
    this._element.insertAdjacentHTML('beforebegin', create.textarea());
    this._element.insertAdjacentHTML('beforeend', create.toolBar(this._options));
    this._element.append(content);

    this._content = SelectorEngine.findOne(`.${CONTENT_CLASS}`, this._element);
    this._toolbar = SelectorEngine.findOne(`.${TOOLBAR_CLASS}`, this._element);

    this._content.innerHTML = initialContent;
    this._updateToolbar();

    // init input`s
    SelectorEngine.find('.form-outline', this._toolbar).forEach((formOutline) => {
      new mdb.Input(formOutline).init();
    });

    const actionHandlers = SelectorEngine.find(
      `.${TOOLBAR_GROUP_CLASS}:not(.${TOOLBAR_TOGGLER_CLASS}) ${COMMAND_SELECTOR}`,
      this._element
    );
    const linkSectionDropdowns = SelectorEngine.find('#links-section', this._element);

    this._initTooltips(this._toolbar);
    this._initDropdowns(this._toolbar);
    this._onActionButtonClick(actionHandlers);
    this._onInput();
    this._onWindowResize();
    this._onBlur();
    this._onOpenDropdown(linkSectionDropdowns);
  }

  _onInput() {
    EventHandler.on(this._content, 'input', () => {
      const textarea = this._element.previousElementSibling;
      textarea.value = this._content.innerHTML;
    });
  }

  _onWindowResize() {
    EventHandler.on(window, 'resize', () => {
      this._updateToolbar();
    });
  }

  _onActionButtonClick(actionHandlers) {
    actionHandlers.forEach((handler) => {
      EventHandler.on(handler, 'mousedown', (e) => {
        e.preventDefault();

        const command = Manipulator.getDataAttribute(handler, 'cmd');

        if (command === 'insertlink' || command === 'insertpicture') {
          const dropdownElement = handler.closest('.dropdown-menu').previousElementSibling;
          const dropdown = mdb.Dropdown.getInstance(dropdownElement);
          dropdown.hide();

          if (this._toolbarToggler) {
            const toolbarDropdown = SelectorEngine.parents(this._toolbarToggler, '.dropdown')[0];
            EventHandler.off(toolbarDropdown, 'hide.bs.dropdown');

            const dropdownInstance = mdb.Dropdown.getInstance(this._toolbarToggler);
            dropdownInstance.hide();

            this._onCloseDropdown(toolbarDropdown);
          }
        }

        if (command === 'close-dropdown') {
          return;
        }

        this._performAction(e.target);

        //* focus back editable div.
        this._content.focus();
      });
    });
  }

  _onBlur() {
    EventHandler.on(this._content, 'blur', () => {
      const selection = document.getSelection();

      this._selection.focusOffset = selection.focusOffset;
      this._selection.focusNode = selection.focusNode;
      this._selection.anchorOffset = selection.anchorOffset;
      this._selection.anchorNode = selection.anchorNode;
    });
  }

  _onOpenDropdown(elements) {
    elements.forEach((element) => {
      EventHandler.on(element, 'show.bs.dropdown', (e) => {
        const selection = document.getSelection();

        if (!selection.baseNode) {
          this._content.focus();
        }

        const url = selection.baseNode.parentElement.href;
        const description = selection.toString();
        const dropdownMenu = e.target.nextElementSibling;
        const commandHandler = SelectorEngine.findOne(COMMAND_SELECTOR, dropdownMenu);
        const command = Manipulator.getDataAttribute(commandHandler, 'cmd');
        const isInsertPictureCommand = command === 'insertpicture';

        if (url) {
          const urlInput = SelectorEngine.findOne('[type="url"]', dropdownMenu);
          urlInput.value = url;
        }

        if (!isInsertPictureCommand) {
          const descriptionInput = SelectorEngine.findOne('[type="text"]', dropdownMenu);
          descriptionInput.value = description;
        }

        dropdownMenu.querySelectorAll('.form-outline input').forEach((input) => {
          input.dispatchEvent(new Event('blur'));
        });
      });
    });
  }

  _onCloseDropdown(element) {
    EventHandler.on(element, 'hide.bs.dropdown', (e) => {
      const clickedElement = document.activeElement;
      const isClickedOnSubmenu = SelectorEngine.parents(clickedElement, '.dropdown-menu')[0];
      const isToolbarToggler = e.target.parentElement.classList.contains(TOOLBAR_TOGGLER_CLASS);

      if (isClickedOnSubmenu && isToolbarToggler) {
        e.preventDefault();
      }
    });
  }

  _isContentFocused() {
    const focusedElement = document.activeElement;
    const closestWysiwyg = focusedElement
      ? SelectorEngine.parents(focusedElement, '.wysiwyg')[0]
      : '';
    const focusedElementUUID = closestWysiwyg
      ? Manipulator.getDataAttribute(closestWysiwyg, 'uuid')
      : '';

    return focusedElementUUID === this._UUID;
  }

  _updateToolbar() {
    const contentWidth = this._content.offsetWidth;
    const tools = SelectorEngine.find(
      `.${TOOLBAR_GROUP_CLASS}:not(.${TOOLBAR_TOGGLER_CLASS})`,
      this._element
    );
    let toolsWidth = 0;

    if (this._toolbarToggler) {
      toolsWidth += this._toolbarToggler.offsetWidth;
    }

    tools.forEach((el, i) => {
      const isHidden = el.classList.contains(HIDE_CLASS);

      toolsWidth += el.offsetWidth || this._toolsWidth[i];

      if (contentWidth < toolsWidth && !isHidden) {
        this._toolsWidth[i] = el.offsetWidth;

        Manipulator.addClass(el, HIDE_CLASS);

        if (!this._toolbarToggler) {
          this._createToolbarToggler();
        }

        this._updateToolbarTogglerMenu();
      } else if (contentWidth > toolsWidth && isHidden) {
        Manipulator.removeClass(el, HIDE_CLASS);
        this._updateToolbarTogglerMenu();
      }
    });
  }

  _randomUUID() {
    let d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line eqeqeq, no-bitwise
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  _createToolbarToggler() {
    this._toolbar.insertAdjacentHTML(
      'beforeend',
      create.toolbarToggler(this._options.wysiwygTranslations)
    );

    this._toolbarToggler = SelectorEngine.findOne(
      `.${TOOLBAR_TOGGLER_CLASS} .dropdown-toggle`,
      this._toolbar
    );

    const toolbarDropdown = SelectorEngine.parents(this._toolbarToggler, '.dropdown')[0];

    this._initDropdowns(toolbarDropdown);
    this._onCloseDropdown(toolbarDropdown);
    this._updateToolbar();
  }

  _updateToolbarTogglerMenu() {
    const toolbarToggler = SelectorEngine.findOne(
      `.${TOOLBAR_TOGGLER_CLASS} .dropdown-menu div`,
      this._element
    );
    const hiddenTools = SelectorEngine.find(`.${TOOLBAR_GROUP_CLASS}.${HIDE_CLASS}`, this._element);

    toolbarToggler.innerHTML = '';

    hiddenTools.forEach((el) => {
      const toolNode = SelectorEngine.children(el, 'div')[0].cloneNode(true);

      toolbarToggler.appendChild(toolNode);
    });

    const actionHandlers = SelectorEngine.find(
      `.${TOOLBAR_GROUP_CLASS} ${COMMAND_SELECTOR}`,
      toolbarToggler
    );

    const linkSectionDropdowns = SelectorEngine.find(
      `#links-section:not(${HIDE_CLASS})`,
      this._element
    );

    this._onOpenDropdown(linkSectionDropdowns);
    this._onActionButtonClick(actionHandlers);
    this._initDropdowns(toolbarToggler);
    this._initTooltips(toolbarToggler);

    if (toolbarToggler.childNodes.length === 0) {
      this._removeToolbarToggler();
    }
  }

  _initDropdowns(element) {
    SelectorEngine.find('[data-toggle="dropdown"]', element).map((el) => {
      let instance = mdb.Dropdown.getInstance(el);

      if (!instance) {
        instance = new mdb.Dropdown(el);
      }

      return instance;
    });
  }

  _initTooltips(element) {
    // init tooltips
    SelectorEngine.find('[data-tooltip="tooltip"]', element).forEach((el) => {
      let instance = mdb.Tooltip.getInstance(el);

      if (!instance) {
        instance = new mdb.Tooltip(el, {
          trigger: 'manual',
        });
      }

      el.addEventListener('mouseenter', (e) => {
        const isDropdown = SelectorEngine.findOne('[data-toggle="dropdown"]', e.target);
        const isOpen = isDropdown ? isDropdown.classList.contains('show') : false;

        if (!isOpen) {
          instance.show();
        }
      });

      el.addEventListener('mouseleave', () => {
        instance.hide();
      });
    });
  }

  _removeToolbarToggler() {
    this._toolbarToggler.closest(`.${TOOLBAR_TOGGLER_CLASS}`).remove();
    this._toolbarToggler = '';
  }

  _performAction(element) {
    if (!this._isContentFocused()) {
      // select proper content div and move caret to end of text
      this._content.focus();
      document.execCommand('selectAll', false, null);
      document.getSelection().collapseToEnd();
    }

    const commandHandler = element.closest(COMMAND_SELECTOR);
    let command = Manipulator.getDataAttribute(commandHandler, 'cmd');
    let argument = Manipulator.getDataAttribute(commandHandler, 'arg');

    if (command === 'insertlink' || command === 'insertpicture') {
      const dropdown = element.closest('.dropdown-menu');
      const urlInput = SelectorEngine.findOne('[type="url"]', dropdown);
      const descriptionInput = SelectorEngine.findOne('[type="text"]', dropdown);
      const selection = document.getSelection();
      const { anchorNode, anchorOffset, focusNode, focusOffset } = this._selection;

      if (descriptionInput) {
        argument = `<a href="${urlInput.value}" target="_blank">${descriptionInput.value}</a>`;
        descriptionInput.value = '';
      } else {
        argument = `<img src="${urlInput.value}" target="_blank" class="img-fluid" />`;
      }

      command = 'insertHTML';
      selection.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset);
      urlInput.value = '';
    }

    if (command === 'toggleHTML') {
      if (this._isCodeShown) {
        this._content.innerHTML = this._content.textContent;
        this._content.classList.remove(SHOW_HTML_CLASS);
        this._isCodeShown = false;
        Manipulator.removeClass(commandHandler, 'active');
      } else {
        this._content.textContent = this._content.innerHTML;
        this._content.classList.add(SHOW_HTML_CLASS);
        this._isCodeShown = true;
        Manipulator.addClass(commandHandler, 'active');
      }
      return;
    }
    document.execCommand(command, false, argument);
  }

  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element);

    config = {
      ...DEFAULT_OPTIONS,
      ...dataAttributes,
      ...config,
    };

    typeCheckConfig(NAME, config, OPTIONS_TYPE);

    return config;
  }

  // Static
  static get NAME() {
    return NAME;
  }

  static jQueryInterface(config, options) {
    return this.each(function () {
      let data = Data.getData(this, DATA_KEY);
      const _config = typeof config === 'object' && config;

      if (!data && /dispose|hide/.test(config)) {
        return;
      }

      if (!data) {
        data = new WYSIWYG(this, _config);
      }

      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError(`No method named "${config}"`);
        }

        data[config](options);
      }
    });
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY);
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation - auto initialization
 * ------------------------------------------------------------------------
 */

SelectorEngine.find(SELECTOR_EXPAND).forEach((el) => {
  let instance = WYSIWYG.getInstance(el);
  if (!instance) {
    instance = new WYSIWYG(el);
  }

  return instance;
});

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .WYSIWYG to jQuery only if jQuery is present
 */

const $ = getjQuery();

if ($) {
  const JQUERY_NO_CONFLICT = $.fn[NAME];
  $.fn[NAME] = WYSIWYG.jQueryInterface;
  $.fn[NAME].Constructor = WYSIWYG;
  $.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return WYSIWYG.jQueryInterface;
  };
}

export default WYSIWYG;
